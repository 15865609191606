import React from 'react'
import { Card, Text } from 'theme-ui'
import Section from '@components/Section'

const Commitment = props => (
  <Section aside title='Our Commitment' {...props}>
    <Card variant='paper'>
      <Text variant='p'>
        Biz gezmeye, farklı aktiviteler bulmaya ve birlikte vakit geçirmeye
        çok düşkün bir ikiliyiz. Bu blogdaki amacımız çok basit. Geçmişe bakıp
        yaptıklarımızı hatırlamak, tekrar yaşamak. Bunları olduğu gibi size aktarmak.
        Okurken yüzünüzü ufak bir gülümsettiysek ne mutlu bize 😌.
      </Text>
    </Card>
  </Section>
)

export default Commitment
